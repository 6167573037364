import { Table, TableProps } from 'antd';
import React from 'react';
import { JobsResponseItem } from 'types/types';

interface JobsCompareProps {
  jobs: JobsResponseItem[];}

interface DataType {
  key: string;
  property: string;
  job1: string | number | undefined;
  job2: string | number | undefined;
}

const columns: TableProps<DataType>['columns'] = [
  {
    title: 'Property',
    dataIndex: 'property',
    key: 'property',
  },
  {
    title: 'Job 1',
    dataIndex: 'job1',
    key: 'job1',
  },
  {
    title: 'Job 2',
    dataIndex: 'job2',
    key: 'job2',
  },
];

const InformationTab: React.FC<JobsCompareProps> = ({ jobs }) => {
  const [job1, job2] = jobs;

  const data: DataType[] = [
    {
      key: '1',
      property: 'Spark Job ID',
      job1: job1.SparkJobID,
      job2: job2.SparkJobID,
    },
    {
      key: '2',
      property: 'Cluster Name',
      job1: job1.ClusterName,
      job2: job2.ClusterName,
    },
    {
      key: '3',
      property: 'Schema Name',
      job1: job1.SchemaName,
      job2: job2.SchemaName,
    },
    {
      key: '4',
      property: 'Table Name',
      job1: job1.TableName,
      job2: job2.TableName,
    },
    {
      key: '5',
      property: 'MV Language',
      job1: job1.MVLanguage,
      job2: job2.MVLanguage,
    },
    {
      key: '6',
      property: 'Service',
      job1: job1.Vendor,
      job2: job2.Vendor,
    },
    {
      key: '7',
      property: 'Spark Job Status',
      job1: job1.SparkJobStatus,
      job2: job2.SparkJobStatus,
    },
    {
      key: '8',
      property: 'Total Executor Count',
      job1: job1.TotalExecutorCount !== undefined && job1.TotalExecutorCount > 0 
        ? job1.TotalExecutorCount 
        : "Executor not found",
      job2: job2.TotalExecutorCount !== undefined && job2.TotalExecutorCount > 0 
        ? job2.TotalExecutorCount 
        : "Executor not found",
    },
    {
      key: '9',
      property: 'Enter Queue',
      job1: job1.QueueingTime > 0 ? 'Yes' : 'No', 
      job2: job2.QueueingTime > 0 ? 'Yes' : 'No', 
    },
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default InformationTab;
