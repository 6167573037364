import React from 'react';
import './SearchableTable.scss';
import SearchableTableBar from 'components/reusables/SearchableTableBar/SearchableTableBar';
import { Checkbox, Table } from 'antd';
import { TablePaginationConfig, TableProps } from 'antd/es/table';
import { JobStatus } from 'components/StatusTag/StatusTag';
import ChidoriIcon from 'assets/icons/chidori-icon.svg';
import clsx from 'clsx';
import dayjs from 'dayjs';

export enum DATA_VIEW {
  TABLE_VIEW = 'TABLE_VIEW',
  CHART_VIEW = 'CHART_VIEW',
}

type SearchableTableProps<T> = {
  showAllJobs: boolean;
  PreContent?: JSX.Element;
  PostContent?: JSX.Element;
  currentView: DATA_VIEW;
  jobIDFilter: string;
  setJobIDFilter: React.Dispatch<React.SetStateAction<string>>;
  jobStatusFilters: JobStatus[];
  setJobStatusFilters: React.Dispatch<React.SetStateAction<JobStatus[]>>;
  dateRangePicker: dayjs.Dayjs[] | null[];
  setDateRangePicker: React.Dispatch<
    React.SetStateAction<dayjs.Dayjs[] | null[]>
  >;
  tableFilter?: string[];
  setTableFilter?: React.Dispatch<React.SetStateAction<string[]>>;
  schemaFilters?: string[];
  setSchemaFilters?: React.Dispatch<React.SetStateAction<string[]>>;
  schemas?: string[];
  tables?: string[];
  Chart?: JSX.Element;
  rowSelection?: TableProps<T>['rowSelection']; 
  loading: boolean;
  pagination: TablePaginationConfig;
  dataSourceTotalCount: number;
} & TableProps<T>;
function SearchableTable<T extends object>({
  showAllJobs,
  PreContent,
  PostContent,
  currentView,
  jobIDFilter,
  setJobIDFilter,
  jobStatusFilters,
  setJobStatusFilters,
  dateRangePicker,
  setDateRangePicker,
  schemas,
  tables,
  tableFilter,
  setTableFilter,
  schemaFilters,
  setSchemaFilters,
  Chart,
  rowSelection,
  loading,
  pagination,
  dataSource,
  dataSourceTotalCount,
  columns,
  ...restProps
}: SearchableTableProps<T>) {
  return (
    <section className="searchable-table__wrapper">
      <section
        className={clsx(
          'searchable-table__bar-container',
          showAllJobs && 'all-jobs'
        )}>
        {PreContent ?? null}
        <SearchableTableBar<T>
          showAllJobs={showAllJobs}
          dataSource={dataSource}
          dataSourceTotalCount={dataSourceTotalCount}
          jobIDFilter={jobIDFilter}
          setJobIDFilter={setJobIDFilter}
          jobStatusFilters={jobStatusFilters}
          setJobStatusFilters={setJobStatusFilters}
          dateRangePicker={dateRangePicker}
          setDateRangePicker={setDateRangePicker}
          tableFilter={tableFilter}
          setTableFilter={setTableFilter}
          schemaFilters={schemaFilters}
          setSchemaFilters={setSchemaFilters}
          schemas={schemas}
          tables={tables}
        />
        {PostContent}
      </section>
      {currentView === DATA_VIEW.TABLE_VIEW ? (
        <Table<T>
          rowKey="SparkJobID"
          rowSelection={  rowSelection ? { type: 'checkbox', ...rowSelection } : undefined}
          className="searchable-table__component"
          dataSource={dataSource}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: (
              <img
                className="searchable-table__loading-spinner"
                src={ChidoriIcon}
                alt="Chidori Icon"
              />
            ),
          }}
          pagination={pagination}
          {...restProps}
        />
      ) : (
        Chart
      )}
    </section>
  );
}

export default SearchableTable;
