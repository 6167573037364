import React, { useContext } from 'react';
import ReactDiffViewer from 'react-diff-viewer-continued';
import { JobsResponseItem } from 'types/types';
import './JobsDiff.scss';

interface JobsCompareProps {
  jobs: JobsResponseItem[];
  oldCode: string;
  newCode: string;
}

const JobsDiff: React.FC<JobsCompareProps> = ({ jobs, oldCode, newCode }) => {

 


  return (
    <div className="jobs-diff-container">
      <ReactDiffViewer
        oldValue={oldCode}
        newValue={newCode}
        splitView={true}
        useDarkTheme={false}
        leftTitle={`Job ID: ${jobs[0].SparkJobID}`}
        rightTitle={`Job ID: ${jobs[1].SparkJobID}`}
        showDiffOnly={false}
        disableWordDiff={true}
      />
    </div>
  );
};

export default JobsDiff;
